import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { NavLink } from "../styles/styledComponent"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Rishav Pandey | 404: Not found" />
    <div className="not-found-page">
      <div className="sec-type-2">
        <div className="container">
          <div className="main-wrapper" style={{ textAlign: "center" }}>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <p>
              <NavLink to="/">Click here to go to homapage</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
